/* @flow */
import React from "react";
import styled from "styled-components";
// import { usePromiseTracker } from "react-promise-tracker";
// import Loader from "react-loader-spinner";

//이미지
import APPLOGO from "./assets/kidya_logo.png";
import WORDLOGO from "./assets/kidya_word.png";
import APP_LOGO_SQUARE from "./assets/kidya_app_logo_s.png";
import WORDLOGOWIDE from "./assets/word_main_color.png";

import PLAY_STORE_BADGE from "./assets/google-play-badge.png";
import APP_STORE_BADGE from "./assets/app-store-badge.svg";

// import BackIcon from "./assets/arrow.png";
// import CheckIcon from "./assets/check.png";
// import LocIcon from "./assets/placeholder.png";

// import DeliveryImage0 from "./assets/kidya_phone.png";
// import DeliveryImage1 from "./assets/delivery_0.jpg";
// import DeliveryImage2 from "./assets/delivery_1.jpg";

// import DropdownIcon from "./assets/dropdown_icon.png";

// import HeartIcon from "./assets/heart.png";
// import KakaoIcon from "./assets/kakao_icon.png";
// import KakaoPayIcon from "./assets/kakao_pay_icon.png";

// import PhoneIcon from "./assets/phone_icon.png";
// import SearchIcon from "./assets/search.png";
// import InfoIcon from "./assets/tab_info_icon.png";

// import MenuIcon from "./assets/menu.png";
// import ActiveMenuIcon from "./assets/active_menu.png";

// import OrderIcon from "./assets/order.png";
// import ActiveOrderIcon from "./assets/active_order.png";

// import DeliveryIcon from "./assets/delivery.png";
// import ActiveDeliveryIcon from "./assets/active_delivery.png";

// import CalendarIcon from "./assets/calendar.png";
// import ActiveCalendarIcon from "./assets/active_calendar.png";

// import NoticeIcon from "./assets/notice.png";
// import ActiveNoticeIcon from "./assets/active_notice.png";

// import ListIcon from "./assets/list.png";
// import ActiveListIcon from "./assets/active_list.png";

// import MembershipIcon from "./assets/membership.png";
// import ActiveMembershipIcon from "./assets/active_membership.png";

// import UserIcon from "./assets/users.png";
// import ActiveUserIcon from "./assets/active_users.png";

// import TagIcon from "./assets/tag.png";
// import ActiveTagIcon from "./assets/active_tag.png";

export {
  APPLOGO,
  WORDLOGO,
  // WORD_WHITE,
  // WORD_MAIN,
  APP_LOGO_SQUARE,
  WORDLOGOWIDE,
  APP_STORE_BADGE,
  PLAY_STORE_BADGE,
  // InfoIcon,
  // TagIcon,
  // ActiveTagIcon,
  // BackIcon,
  // LocIcon,
  // CheckIcon,
  // HeartIcon,
  // CalendarIcon,
  // ActiveCalendarIcon,
  // NoticeIcon,
  // ActiveNoticeIcon,
  // ListIcon,
  // ActiveListIcon,
  // MenuIcon,
  // ActiveMenuIcon,
  // OrderIcon,
  // ActiveOrderIcon,
  // DeliveryIcon,
  // ActiveDeliveryIcon,
  // SearchIcon,
  // DropdownIcon,
  // MembershipIcon,
  // ActiveMembershipIcon,
  // UserIcon,
  // ActiveUserIcon,
  // KakaoIcon,
  // KakaoPayIcon,
  // DeliveryImage0,
  // DeliveryImage1,
  // DeliveryImage2,
  // PhoneIcon,
};

//스타일, 자주쓰는 상수 정의
export const ContentPaddingCss = `
  padding-left: 10vw;
  padding-right: 10vw;
  @media (max-width: 1023px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`;

export const ContentPaddingH = "10vw";
export const ContentPaddingV = "2vh";
export const ContentHeight = "70vh";
export const ContentWidth = `
 width:80vw;
 @media (max-width: 1023px) {
   width:90vw;
 }
`;

export const HeaderHeight = "13vh";
export const HeaderImageHeight = "7vh";
export const TabHeight = "6vh";
export const TitlePaddingHorizontal = "2vw";
export const Colors = {
  greyOp: "rgba(100,100,100,0.1)",
  red: "#fd4f50", //253, 79, 80
  redOp: "rgba(253, 79, 80,0.7)",
  charcoalGrey: "#343a40",
  charcoalGreyOp: "rgba(52,58,64,0.6)",
  paleGrey: "#f0f0f0",
  grey: "#666",
  lightBlueGrey: "rgb(206,212,218)",
  veryLightBlue: "#e9ecef",
};
export const FontFamily = "";
export const FontSize = "12px";
export const MainColor: string = "#83BE41";
export const MainColorOp = "rgba(131, 190, 65,0.3)";

//styled-component로 flex 컴포넌트 이용
//RN이랑 비슷한 컴포넌트 명으로 사용가능

export const Header = styled.header`
  display: flex;
  flex: 0 0 auto;
  min-height: ${HeaderHeight};
  padding-left: ${ContentPaddingH};
  padding-right: ${ContentPaddingH};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;
  @media (max-width: 767px) {
    @media all and (orientation: portrait) {
      justify-content: space-between;
      height: 8vh;
      min-height: 6vh;
    }
  }
  ${ContentPaddingCss};
`;

export const View = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  ${(props) => props.styles && props.styles}
`;

export const Hr = () => (
  <View
    styles={`width:1px; height:2.5vh; background:${Colors.veryLightBlue};`}
  />
);

export const Text = styled.p`
  pointer-events: ${(props) => (props.cursor ? "auto" : "none")};
  font-size: 0.8rem;
  @media (max-width: 767px) {
    font-size: 0.7rem;
  }
  ${(props) => props.styles && props.styles}
`;

export const Span = styled.span`
  pointer-events: ${(props) => (props.cursor ? "auto" : "none")};
  font-size: 0.8rem;
  @media (max-width: 767px) {
    @media all and (orientation: portrait) {
      font-size: 0.7rem;
    }
  }
  ${(props) => props.styles && props.styles}
`;

export const A = styled.a`
  ${(props) => props.styles && props.styles}
`;

export const TextInput = styled.input`
  ${(props) => props.styles && props.styles}
`;

export const TextArea = styled.textarea`
  ${(props) => props.styles && props.styles}
`;

export const Image = styled.img`
  ${(props) => props.styles && props.styles}
`;

export const TouchableOpacity = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding-left: 1vw;
  padding-right: 1vw;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
  ${(props) => props.styles && props.styles}
`;

export const Table = styled.table`
  ${(props) => props.styles && props.styles}
`;

export const Thead = styled.thead`
  ${(props) => props.styles && props.styles}
`;

export const Tbody = styled.tbody`
  ${(props) => props.styles && props.styles}
`;

// const StyledTh = styled.th`
//   ${props=>props.styles&&props.styles}
// `;
// export const Td = styled.td`
//   ${(props) => props.styles && props.styles}
// `;

// export const Tr = styled.tr`
//   ${(props) => props.styles && props.styles}
// `;

// export const Thh = styled.th`
//   ${(props) => props.styles && props.styles}
// `;

// export const Th = ({ label, onClick, filter, styles }: any) => {
//   return (
//     <Thh styles={styles}>
//       <View
//         onClick={onClick}
//         styles={[`flex-direction:row;`, onClick && `&:hover{opacity:0.6;}`]}
//       >
//         <Text>{label}</Text>
//         {filter > 0 && (
//           <Image
//             styles={[
//               `width:0.9rem;height:0.9rem;`,
//               filter === 2 && `transform:rotate(180deg);`,
//             ]}
//             src={DropdownIcon}
//           />
//         )}
//       </View>
//     </Thh>
//   );
// };

type FlatListProps = {
  style: string,
  contentContainerStyle: string,
  ListHeaderComponent?: any,
  ListEmptyComponent?: any,
  ListFooterComponent?: any,
  data: Array<any>,
  renderItem: (item: any, index: number) => any,
};
type FlatListState = {};

export class FlatList extends React.Component<FlatListProps, FlatListState> {
  render() {
    const {
      style,
      titleStyle,
      renderItem,
      contentContainerStyle,
      data,
      ListHeaderComponent,
      ListFooterComponent,
      ListEmptyComponent,
      title,
    } = this.props;
    return (
      <View styles={style}>
        {title && <Text styles={titleStyle}>{title}</Text>}
        <Table styles={contentContainerStyle}>
          <Thead>{ListHeaderComponent ? ListHeaderComponent : null}</Thead>
          <Tbody>
            {renderItem && data && data.length > 0 && data.map(renderItem)}
          </Tbody>
        </Table>
        {data.length === 0 && ListEmptyComponent ? ListEmptyComponent : null}
        {ListFooterComponent ? ListFooterComponent : null}
      </View>
    );
  }
}

// export const Spinner = (props: any) => {
//   const { promiseInProgress } = usePromiseTracker();
//   return (
//     promiseInProgress && (
//       <View
//         styles={`
//           position:fixed;
//           top:0;
//           left:0;
//           align-items:center;
//           align-self:center;
//           justify-content:center;
//           width:100%;
//           height:100%;
//       `}
//       >
//         <Loader type="ThreeDots" color={MainColor} height={70} width={70} />
//         <Text>잠시만 기다려주세요...</Text>
//       </View>
//     )
//   );
// };

export const formatNumber = (inputNumber: number) => {
  return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
