import "./index.css";

import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import {
  View,
  Text,
  Image,
  APPLOGO,
  WORDLOGOWIDE,
  APP_STORE_BADGE,
  PLAY_STORE_BADGE,
} from "./defaultStyle";

const HomePage = () => (
  <View styles={styles.container}>
    <View styles={styles.mainWrapper}>
      <View styles={styles.appLogoWrapper}>
        <Image styles={styles.applogo} src={APPLOGO} />
        <Text styles={styles.headerText}>
          매일 찾아가는
          <br />
          우리 아이 밥상, 아이야
        </Text>
      </View>
      <View styles={styles.wordLogoWrapper}>
        <Image styles={styles.wordlogo} src={WORDLOGOWIDE} />
      </View>

      <View styles={styles.downloadWrapper}>
        <a href="https://apps.apple.com/us/app/아이야-kidya-홈메이드-이유식-배달/id1487926142">
          <Image styles={styles.downloadLogo} src={APP_STORE_BADGE} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.kidyaapp&pcampaignid=web_share">
          <Image styles={styles.downloadLogoPlay} src={PLAY_STORE_BADGE} />
        </a>
      </View>
    </View>

    <View styles={styles.footerContainer}>
      <View styles={styles.footerRow}>
        <View styles={styles.footerLeft}>
          <View>
            <Text styles={styles.footerText}>
              [상호] 아이야 [대표자] 김나영
            </Text>
            <Text styles={[styles.footerText]}>
              [매장] 서울 영등포구 문래북로 108 문래동 태영 데시앙상가 106호
            </Text>
            <Text styles={styles.footerText}>[전화] 02-2068-2688</Text>
          </View>
        </View>
        <View styles={styles.footerRight}>
          <Text styles={styles.footerText}>[카카오톡 ID] kidya_</Text>
          <Text styles={styles.footerText}>
            [영업시간] 평일 09:00~19:00 / 토요일, 일요일, 공휴일 휴무
          </Text>
        </View>
      </View>
      <Text styles={[styles.footerText, `width:auto; align-self:center;`]}>
        (C) 2024. 아이야 All rights reserved.
      </Text>
    </View>
  </View>
);

const styles = {
  container: `
    width:100vw;
    height:100vh
    justify-content:space-between;
  `,
  mainWrapper: `
    flex:1;
    align-items:center;
    justify-content:center;
  `,
  headerText: `
      font-weight:500;
    `,
  appLogoWrapper: `
      align-items:center;
      flex-direction:row;
      
    `,
  applogo: `
      height: 8vh;
      margin-right:1vw;
    `,
  wordLogoWrapper: `
      margin-top:12px;
      justify-content:center;
      align-items:center;
    `,
  wordlogo: `
        height: 8vh;
        width:auto;
        padding-top:1vh;
    `,
  downloadWrapper: `
    margin-top:24px;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    @media (max-width: 767px) {
      @media all and (orientation:portrait) {
        margin-top:32px;
        flex-direction:column;
      }
    }
  `,
  downloadLogo: `
    height:60px;
    margin-right:10px;
    @media (max-width: 767px) {
      @media all and (orientation:portrait) {
        margin-right:0px;
      }
    }
  `,
  downloadLogoPlay: `
    height:75px;
  `,
  footerContainer: `
    min-height:15vh;
    align-self:center;
    margin-top:5vh;
    padding:3vh 0;
  `,
  footerRow: `
    justify-content:center;
    flex-direction:row;
    @media (max-width: 767px) {
      @media all and (orientation:portrait) {
        flex-direction:column;
      }
    }
  `,
  footerLeft: `
    width:40vw;
    flex-direction:row;
  `,
  footerRight: `
    
  `,
  footerIcon: `
    height: 10vh;
    width:auto;
    margin-right:2vw;
    border-radius:2vh;
  `,
  footerText: `
   
  `,
};

ReactDOM.render(<HomePage />, document.getElementById("root"));

serviceWorker.unregister();
